<template>
  <be-modal
    id="new-annual-report"
    v-model="showModal"
    :title="$t('models.annual_report.new')"
    :ok-title="$t('models.annual_report.create')"
    :cancel-title="$t('buttons.titles.cancel')"
    :ok-disabled="!annualReport.year"
    @ok.prevent="$refs.form.submit()"
  >
    <form ref="form" method="post" :action="url('annual_reports')">
      <input type="hidden" name="authenticity_token" :value="$csrfToken" />

      <be-form-group
        label-for="end_at"
        :label="$t('components.annual_reports.financial_year_end')"
        :error="getErrors(annualReport, ['end_at'])"
        required
      >
        <be-form-select
          id="year"
          v-model="annualReport.end_at"
          name="annual_report[end_at]"
          :options="endAtOptions"
          @change="clearErrors(annualReport, ['end_at'])"
        />
      </be-form-group>

      <be-form-group
        v-if="isFoundation && subFoundationOptions.length > 1"
        label-for="subfoundation"
        :label="$t('components.annual_reports.prefill_subfoundation')"
        data-test-id="prefill-subfoundation"
      >
        <be-form-select
          id="subfoundation"
          v-model="chosenSubfoundation"
          :options="subFoundationOptions"
          @change="prefillSubfoundation"
        />
      </be-form-group>

      <be-form-group
        v-if="isFoundation"
        label-for="subtitle"
        :label="$t('components.annual_reports.subtitle')"
        :error="getErrors(annualReport, ['subtitle'])"
      >
        <be-form-input
          id="subtitle"
          v-model="annualReport.subtitle"
          name="annual_report[subtitle]"
          @change="clearErrors(annualReport, ['subtitle'])"
        />
      </be-form-group>

      <be-form-group
        label-for="organization_number"
        :label="$t('components.annual_reports.organization_number')"
        :error="getErrors(annualReport, ['organization_number'])"
        :required="!isFoundation"
      >
        <be-form-input
          id="organization_number"
          v-model="annualReport.organization_number"
          name="annual_report[organization_number]"
          @change="clearErrors(annualReport, ['organization_number'])"
        />
      </be-form-group>
    </form>
  </be-modal>
</template>

<script>
export default {
  inject: ["subfoundations"],

  props: {
    newAnnualReport: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      annualReport: {
        organization_number: null,
      },

      chosenSubfoundation: null,
      showModal: false,
    };
  },

  computed: {
    subfoundationsByOrgNumber() {
      return this.subfoundations.reduce((map, subfoundation) => {
        map[subfoundation.organization_number] = subfoundation;
        return map;
      }, {});
    },

    isFoundation() {
      return this.$currentCompany.type_of_organization == "foundation";
    },

    startOfCurrentFiscalYear() {
      const startMonth = this.$currentCompany.financial_year_start;

      const date = new Date();
      date.setMonth(startMonth - 1);
      date.setDate(1);
      date.setUTCHours(0, 0, 0);

      if (date > new Date()) {
        date.setFullYear(date.getFullYear() - 1);
      }

      return date;
    },

    endAtOptions() {
      const years = [];
      for (let i = 0; i < 4; i++) {
        const endDate = new Date(this.startOfCurrentFiscalYear.getTime());
        endDate.setFullYear(endDate.getFullYear() - i);
        // Set the last day of the previous month
        endDate.setDate(0);

        years.push({
          text: this.$d(endDate, "date"),
          value: endDate,
        });
      }
      return years;
    },

    subFoundationOptions() {
      return this.subfoundations.map((subfoundation) => ({
        text: subfoundation.name,
        value: subfoundation.organization_number,
      }));
    },
  },

  mounted() {
    if (this.newAnnualReport) {
      this.annualReport = this.cloneDeep(this.newAnnualReport);
      this.showModal = true;
    } else {
      this.annualReport.year = this.endAtOptions[0].value;

      if (!this.isFoundation) {
        this.annualReport.organization_number =
          this.$currentCompany.organization_number;
      }
    }
  },

  methods: {
    prefillSubfoundation(org_num) {
      const org = this.subfoundationsByOrgNumber[org_num] || {};
      if (org) {
        this.annualReport.organization_number = org.organization_number;
        this.annualReport.subtitle = org.name;
      }
    },
  },
};
</script>
