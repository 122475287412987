<template>
  <div>
    <be-button
      v-be-modal="'confirm-perform-user-merge'"
      v-be-tooltip="{
        title: $t('views.users.merge_requests.merge_request.incorrect_device'),
        disabled: correctDevice,
      }"
      variant="primary"
      class="ml-2"
      :disabled="!correctDevice"
    >
      {{
        $t(
          "components.user.merge_requests.confirm_perform_user_merge.button_title"
        )
      }}
    </be-button>

    <confirm-modal
      id="confirm-perform-user-merge"
      :title="
        $t('components.user.merge_requests.confirm_perform_user_merge.title')
      "
      :ok-title="
        $t(
          'components.user.merge_requests.confirm_perform_user_merge.button_title'
        )
      "
      :valid-input-text="confirmText"
      confirm-with-input
      @confirm="handleConfirm"
    >
      <merge-request-user
        class="m-4"
        mode="keep"
        :leading-text="
          $t(
            'components.user.merge_requests.merge_request_user.result_after_merge'
          )
        "
        :user="user"
        :will-lose-password="willLosePassword"
        :transferred-integrations="transferredIntegrations"
        :source-organizations="sourceOrganizations"
        :target-organizations="targetOrganizations"
        :source-client-managers="sourceClientManagers"
        :target-client-managers="targetClientManagers"
        :display-created-at="false"
      ></merge-request-user>

      <be-alert variant="warning">
        {{
          $t(
            "components.user.merge_requests.confirm_perform_user_merge.undo_warning"
          )
        }}
      </be-alert>
    </confirm-modal>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },

    willLosePassword: {
      type: Boolean,
      required: true,
    },

    transferredIntegrations: {
      type: Array,
      required: true,
    },

    sourceClientManagers: {
      type: Array,
      required: false,
      default: () => [],
    },

    targetClientManagers: {
      type: Array,
      required: false,
      default: () => [],
    },

    sourceOrganizations: {
      type: Array,
      required: false,
      default: () => [],
    },

    targetOrganizations: {
      type: Array,
      required: false,
      default: () => [],
    },

    oldEmailOrName: {
      type: String,
      required: true,
    },

    confirmPath: {
      type: String,
      required: true,
    },

    correctDevice: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    confirmText() {
      const transformedNameOrEmail = this.oldEmailOrName
        .toLowerCase()
        .replace(/\s+/g, "-");

      return `remove-${transformedNameOrEmail}`;
    },
  },

  methods: {
    async handleConfirm() {
      try {
        await axios.post(this.confirmPath);
        window.location.reload();
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
