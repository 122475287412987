<template>
  <div class="card-footer d-md-flex justify-content-between align-items-center">
    <attendance-save-status
      v-if="currentPage === 'attendances'"
      class="mb-3 mb-md-0"
    />

    <save-status
      v-else
      class="mb-3 mb-md-0"
      :loading="loading"
      :failed-to-save="failedToSaveSomeItem"
      :validation-failed="formInvalid"
      :validation-failed-text="
        $t('components.meetings.material.editor.form_invalid')
      "
      :unsaved-changes="isDirty"
      :last-saved-at="lastSavedAt"
      :last-saved-at-prefix="lastSavedAtPrefix"
    />

    <div v-if="isMinutes">
      <be-button
        v-if="meeting.meeting_series_type === 'annual'"
        v-be-tooltip="{
          title: reviewButtonTooltip,
          disabled: !reviewButtonDisabled,
        }"
        :variant="currentPage == 'attendances' ? 'outline-primary' : 'primary'"
        @click="requestSignatures"
        :disabled="reviewButtonDisabled"
      >
        {{ $t("components.meetings.material.editor.request_signatures") }}
      </be-button>

      <be-button
        v-else
        v-be-tooltip="{
          title: reviewButtonTooltip,
          disabled: !reviewButtonDisabled,
        }"
        :variant="currentPage == 'attendances' ? 'outline-primary' : 'primary'"
        :disabled="reviewButtonDisabled"
        @click="commentsAndReview"
      >
        {{ $t("components.meetings.material.editor.review") }}
      </be-button>

      <be-button
        v-if="currentPage === 'attendances'"
        variant="primary"
        @click="$emit('update-page', 'editor')"
      >
        {{ $t("models.material.minutes.edit") }}
      </be-button>
    </div>

    <template v-else-if="isAgenda">
      <span
        v-be-tooltip="{
          title: $t(
            'components.meetings.material.editor.tooltips.form_invalid'
          ),
          disabled: !formInvalid,
        }"
      >
        <be-button
          v-if="secretaryDoneAt"
          variant="outline-primary"
          :disabled="formInvalid"
          :loading="loading"
          :href="`${meeting.paths.base}/invitations`"
        >
          {{ $t("components.meetings.editor.show_invitations") }}
        </be-button>

        <be-button
          v-else
          variant="primary"
          :disabled="formInvalid"
          :loading="loading"
          @click="agendaDone"
        >
          {{ $t("components.meetings.material.editor.mark_agenda_done") }}
        </be-button>
      </span>
    </template>
  </div>
</template>

<script>
import SaveStatus from "@/components/shared/SaveStatus.vue";
import AttendanceSaveStatus from "./AttendanceSaveStatus.vue";

import materialType from "./materialState";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SaveStatus,
    AttendanceSaveStatus,
  },

  mixins: [materialType],

  props: {
    currentPage: {
      type: String,
      required: true,
    },
  },

  emits: ["update-page"],

  computed: {
    ...mapGetters("attendances", ["hasSecretaryAndReviewers"]),

    ...mapGetters("material", [
      "isDirty",
      "material",
      "lastItemUpdatedAt",
      "emptyTasksOrDecisions",
      "formInvalid",
      "failedToSaveSomeItem",
      "loading",
    ]),

    lastSavedAt() {
      if (this.isAgenda && this.secretaryDoneAt) {
        return this.$t(
          "components.meetings.material.editor.save_state.secretary_done_at_w_time",
          {
            time: this.$d(
              this.secretaryDoneAt,
              this.dateFormatIfSameDay(this.secretaryDoneAt)
            ),
          }
        );
      } else {
        return this.lastItemUpdatedAt;
      }
    },

    lastSavedAtPrefix() {
      return this.isAgenda
        ? this.$t(
            "components.meetings.material.editor.save_state.last_saved_at_prefix_agenda"
          )
        : this.$t(
            "components.meetings.material.editor.save_state.last_saved_at_prefix_minutes"
          );
    },

    secretaryDoneAt() {
      if (this.material.secretary_done_at) {
        return new Date(this.material.secretary_done_at);
      }

      return null;
    },

    reviewButtonDisabled() {
      return (
        this.formInvalid ||
        this.isDirty ||
        this.loading ||
        !this.hasSecretaryAndReviewers
      );
    },

    reviewButtonTooltip() {
      if (this.isDirty) {
        return this.$t(
          "components.meetings.material.editor.tooltips.unsaved_changes"
        );
      } else if (this.formInvalid) {
        return this.$t(
          "components.meetings.material.editor.tooltips.form_invalid"
        );
      } else if (!this.hasSecretaryAndReviewers) {
        return this.$t(
          "components.meetings.material.attendances.requirements_warning"
        );
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapActions("material", ["markAgendaAsDone"]),

    async agendaDone() {
      if (this.emptyTasksOrDecisions) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: this.$t("buttons.titles.continue"),

          title: this.$t(
            "components.meetings.material.editor.confirm_agenda_mark_as_done_with_empty_tasks_and_decisions"
          ),
        });

        if (!isConfirmed) {
          this.addErrorsToEmptyTasksDecisions(this.$t("errors.messages.blank"));
          return false;
        }
      }

      const response = await this.markAgendaAsDone();
      if (response) {
        window.location.replace(`${this.meeting.paths.base}/invitations`);
      } else {
        console.error("Could not save material");
      }
    },

    async commentsAndReview() {
      if (this.emptyTasksOrDecisions) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: this.$t("buttons.titles.continue"),

          title: this.$t(
            "components.meetings.material.editor.confirm_minutes_review_with_empty_tasks_and_decisions"
          ),
        });

        if (!isConfirmed) {
          this.addErrorsToEmptyTasksDecisions(this.$t("errors.messages.blank"));
          return false;
        }
      }
      window.location.replace(`${this.meeting.paths.base}/minutes/review`);
    },

    async requestSignatures() {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("buttons.titles.continue"),

        title: this.$t(
          "components.meetings.material.editor.confirm_request_signatures"
        ),
      });

      if (isConfirmed) {
        try {
          await axios.post(
            `${this.meeting.paths.base}/minutes/signature_request`
          );
          window.location.reload();
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
